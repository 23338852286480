import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/loginContext';
import { Grid } from '@mui/material';

import '../../css/login.css';
import Loading from '../loading/loading';
import AlertInfo from '../alert/alert';
import imgOneLogin from '../../images/imgOneLogin.svg';
import imgTitleLogin from '../../images/ingTitleLogin.svg';
import imgTwoLogin from '../../images/imgTwoLogin.svg';

function FormLogin() {
    const { validateLogin, setAccount, setUserInfo } = React.useContext(LoginContext);
    const [email, setEmail] = React.useState('');
    const [startLloading, setStartLoading] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [typeAlert, setTypeAlert] = React.useState('');
    const [messageError, setMessageError] = React.useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email.trim()) {
            setStartLoading(false);
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageError("Error: Por favor, ingrese su correo electrónico.");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.trim())) {
            setStartLoading(false);
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageError("Error: Por favor, ingrese un correo electrónico válido.");
            return;
        }

        setStartLoading(true);
        const cleanedEmail = email.toLowerCase().trim();
        console.log( "CleanEmail from FormLogin: ", cleanedEmail );
        const response = await validateLogin(cleanedEmail);
        console.log( "Response of ValidateLogin from FormLogin: ", response );

        if (response) {
            setUserInfo(response.userInformation.user_email)
            setStartLoading(false);
            navigate(`/dashboard`, { state: response });
        } else {
            setStartLoading(false);
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageError("Error: Credenciales incorrectas. Por favor, inténtelo de nuevo.");
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
    }

    const connectWallet = () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(result => {
                    setAccount(result);
                    navigate('/dashboard');
                })
                .catch(error => {
                    console.log(error);
                })

        } else {
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageError("Error: ¡Necesitas MetaMask! Descárgalo en https://metamask.io/");
            setTimeout(() => {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
            }, 3000);
        }
    }

    return (
        <>
            <Grid container spacing={0} style={{ height: '100%' }}>
                <Grid item xs={12} md={4} className='containerOneLogin'>
                    <img className='imgOneLogin' src={imgOneLogin} alt="" />
                </Grid>
                <Grid item xs={12} md={4} className='containerTwoLogin'>
                    <div className='boxLogin'>
                        <img className='imgTitleLogin' src={imgTitleLogin} alt="" />
                        <p className='textBoxLogin'>Al Ingresar a nuestra página estás aceptando nuestra política de privacidad y sus términos y condiciones</p>
                        <form action="" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name='email'
                                id=""
                                className='inputLogin'
                                placeholder='Ingresa aqui tu correo'
                                value={email}
                                onChange={handleChange}
                                style={{ border: !openAlert ? ' 1px solid rgba(69, 211, 161, 1)' : ' 1px solid red' }}
                            />
                            <button type='submit' className='buttonlogin'>Ingresar</button>
                        </form>
                        {/* <button type='' className='buttonloginEthereum' onClick={connectWallet}>Ingresar con Ethereum</button> */}
                    </div>
                </Grid>
                <Grid item xs={12} md={4} className='containerThreeLogin'>
                    <p className='textThree'>© 2023.  Todos los derechos reservados para XM S.A. E.S.P</p>
                    <div style={{ textAlign: 'end' }}>
                        <img className='imgTwoLogin' src={imgTwoLogin} alt="" />
                    </div>
                </Grid>
            </Grid>
            <Loading status={startLloading} />
            <AlertInfo openAlert={openAlert} setOpenAlert={setOpenAlert} typeAlert={typeAlert} messageError={messageError} />
        </>
    );
}

export default FormLogin;