import { styled } from '@mui/material';
import { Button } from '@mui/material';

export const ButtonStyled = styled(Button)(({ theme }) => ({
    background: 'rgba(69, 211, 161, 1)',
    width: '17vw',
    height: '6vh',
    borderRadius: '50px',
    fontSize: '1.5vw',
    fontWeight: 700,
    fontFamily: 'Monospace',
    color: 'rgba(25, 36, 66, 1)',
    textTransform: 'none',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      background: 'rgba(69, 211, 161, 1)',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      height: '70px',
      fontSize: '16px',
      borderRadius: '50%',
      marginLeft: 10
    },
  }));

  export const Input = styled('input')({
    display: 'none',
  });