import * as React from 'react';
import { LoginContext } from '../../contexts/loginContext';
import { useNavigate } from 'react-router-dom';
import { QrScanner } from '@yudiel/react-qr-scanner';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

import Loading from '../loading/loading';
import AlertInfo from '../alert/alert';
import ModalSearcheImage from './modalSearchImage';

import '../../css/dashboard.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        // backgroundColor: 'rgba(251, 129, 190, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        // backgroundColor: 'rgba(251, 129, 190, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },

    '& .MuiDialog-paper': {
        padding: theme.spacing(1),
        // backgroundColor: 'rgba(251, 129, 190, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
}));

export default function ModalScannerQr({ type, userEmail }) {
    const { validateLogin } = React.useContext(LoginContext);
    const [open, setOpen] = React.useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    const [isScannerOpen, setIsScannerOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
        handleOpenScanner();
    };
    const handleClose = () => {
        setOpen(false);
        handleCloseScanner();
    };

    const handleOpenScanner = () => {
        setIsScannerOpen(true);
    };

    const handleCloseScanner = () => {
        setIsScannerOpen(false);
    };

    const handleNavigate = async (data) => {
        if (userEmail === null) {
            navigate(`/nft_claim/${data[4]}`);
        } else {

            setOpenLoader(true);
            const info = `${userEmail}/${data[4]}`
            const result = await validateLogin(info);

            if (result) {
                setOpen(false);
                setIsScannerOpen(false);
                setOpenLoader(false);
                navigate(`/dashboard`, { state: result });
            }
        }
    }

    function isValidQRCode(result) {
        const urlPattern = /^https:\/\/greenlist\.azurewebsites\.net\/nft_claim\/[a-fA-F0-9]+$/;
        return urlPattern.test(result);
    }

    return (
        <React.Fragment>
            {
                type === 1 ?
                    <button className='buttonQr' onClick={handleClickOpen}>Escanear con QR</button>
                    :
                    <div className='buttonQrTwo' >
                        <button className='textButtonQr' onClick={handleClickOpen}><QrCodeScannerIcon /></button>
                        <ModalSearcheImage userEmail={userEmail || null} text={<ImageSearchIcon/>}/>
                    </div>
            }

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle sx={{
                    m: 0, p: 2, pl: 3, background: 'rgba(251, 129, 190, 1)',
                    fontFamily: 'Monospace', fontWeight: 700, borderRadius: 15
                }}
                    id="customized-dialog-title">
                    Escanear QR
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 14,
                        top: 19,
                        color: 'rgba(25, 36, 66, 1)',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ p: { xs: 0, md: '18px' } }}>
                    {isScannerOpen && (
                        <QrScanner
                            onDecode={async (result) => {

                                if (isValidQRCode(result)) {
                                    const data = result.split('/');
                                    await handleNavigate(data);
                                } else {
                                    setOpenAlert(true);
                                    setMessageError(' El Qr escaneado no es valido')
                                }

                            }}
                            onError={(error) => console.log(error?.message)}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <AlertInfo openAlert={openAlert} setOpenAlert={setOpenAlert} typeAlert={'error'} messageError={messageError} />
                </DialogActions>
            </BootstrapDialog>

            <Loading status={openLoader} />

        </React.Fragment >
    );
}