import * as React from 'react';
import Grid from '@mui/material/Grid';

import imgOneBenefits from '../../images/imgOneBenefit.svg';
import imgOTwoBenefits from '../../images/imgTwoBenefit.svg';

function Benefits() {
    return (
        <div style={{ marginBottom: 150 }}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                    <div className='containerBenefits'>
                        <img className='imgBenefits' src={imgOneBenefits} alt="" />
                        <p className='textBenefit'>Accede a futuros Beneficios o premios de nuestros programas.</p>
                    </div>

                </Grid>

                <Grid item xs={12} md={6}>
                    <div className='containerBenefits'>
                        <img className='imgBenefits' src={imgOTwoBenefits} alt="" />
                        <p className='textBenefit'>Tendrás un álbum de arte digital con los eventos en los cuales participaste.</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Benefits;    