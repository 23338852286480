import React, { useState } from 'react';
import { LoginContext } from '../../contexts/loginContext';
import { Grid, Box } from '@mui/material';

import ModalDark from '../modals/modalDark';
import ModalJab from '../modals/modalJag';
import ModalScannerQr from '../modals/modalScannerQr';
import QRReaderComponent from '../modals/modalSearchImage';
import ModalJaime from '../modals/modalJaime';
import Loading from '../loading/loading';

import '../../css/dashboard.css';
import ImgDashTwo from '../../images/imgDashTwo.svg';

import SwitchEnv from "./SwitchEnv";
import { useEffect } from 'react';
import PopUpTest from './PopUpTest';

function InfoUser({ infoUser }) {
    const { requestInfoNft, openLoader } = React.useContext(LoginContext);
    const [openModalDark, setOpenModalDark] = React.useState(false);
    const [infoModal, setInfoModal] = React.useState({ title: '', type: '', img: '' });
    const [openModalJag, setOpenModalJag] = React.useState(false);
    const [infoModalJag, setInfoModalJag] = React.useState({});
    const [env, setEnv] = React.useState(false);

    const [openModalJaime, setOpenModalJaime] = React.useState(false);
    const [infoModalJaime, setInfoModalJaime] = React.useState({});

    const [loader, setLoader] = React.useState(false);
    const [txId, setTxId] = React.useState('');
    const [nftId, setNftId] = React.useState('');
    const [nft, setNft] = React.useState( '' );
    
    // const nft= requestInfoNft(IdNft);

    useEffect(() => {
        if (typeof infoUser === null) {
            setEnv(false);
        } else {
            setEnv(infoUser.env_mode);
        }
    }, [infoUser])


    const handleOpenModalJab = async (IdNft) => {
        try {
            const nft = await requestInfoNft(IdNft);
            setNft( nft );
            filterTxid(nft._id);
            if (nft.tipo === 1) {
                setOpenModalJag(true);
                setInfoModalJag(nft);
                // filterTxid(nft._id);
            } else if (nft.tipo === 2) {
                setOpenModalDark(true);
                setInfoModal(nft);
                // filterTxid(nft._id);
            } else if (nft.tipo === 3) {
                setOpenModalJaime(true);
                setInfoModalJaime(nft);
                // filterTxid(nft._id);
            }

            setLoader(false);
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    }

    const filterTxid = (id) => {
        const info = infoUser.user_nfts.find(nft => nft._id === id);
        setTxId(info.tx_id);
        setNftId(info.nft_id);
    }

    return (
        <div style={{ position: 'relative' }}>
            <Grid container spacing={0} sx={{ height: { xs: '', md: '100vh' } }}>
                <Grid item xs={12} md={4} className='gridInfoUser'
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                    }}>

                    <Box className='boxInfoUser'>
                        <img src={ImgDashTwo} alt="" className='imgDashTwo' />
                        <p className='emailUser'>{infoUser?.user_email}</p>
                        <p className='emailUserKey'>{infoUser.user_publ_key}</p>
                        {/* < PopUpTest /> */}
                        <ModalScannerQr type={1} userEmail={infoUser?.user_email || null} />
                        <QRReaderComponent userEmail={infoUser?.user_email || null} text='Escanear Imagen' />
                    </Box>

                </Grid>

                <Grid item xs={12} md={8} sx={{ display: { xs: 'block', md: 'none' } }} className='containerTwo'>
                    <Grid container spacing={0}>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <img src={ImgDashTwo} alt="" className='imgDashTwo-2' />
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p className='textEmail'>
                                {infoUser?.user_email}
                            </p>
                            <div>
                                {/* < PopUpTest /> */}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={8}
                    sx={{ paddingTop: { xs: '6vh', md: '5vh' } }}
                    style={{
                        maxHeight: '100vh', overflowY: 'scroll',
                        display: 'flex', justifyContent: 'end', alignItems: 'start'
                    }}
                >

                    {
                        env === 0 ?
                            <Grid container spacing={{ xs: 0, md: 8 }} style={{ width: '100%' }}>
                                {infoUser?.user_nfts && infoUser?.user_nfts.map((nft, index) => (
                                    <Grid item xs={4} key={index} className='pentagon-mask'>
                                        <img src={nft.url_image} alt="" className='ImgDashThree' onClick={() => handleOpenModalJab(nft._id)} />
                                        <p className='titleNft'>{nft.title}</p>
                                    </Grid>
                                ))}
                            </Grid>

                            :

                            <Grid container spacing={{ xs: 0, md: 8 }} style={{ width: '100%' }}>
                                {infoUser?.user_nfts_test && infoUser?.user_nfts_test.map((nft, index) => (
                                    <Grid item xs={4} key={index} className='pentagon-mask'>
                                        <img src={nft.url_image} alt="" className='ImgDashThree' onClick={() => handleOpenModalJab(nft._id)} />
                                        <p className='titleNft'>{nft.title}</p>
                                    </Grid>
                                ))}
                            </Grid>
                    }
                    
                    {/* <div>
                        < SwitchEnv user={ infoUser } setEnv={ setEnv } />
                    </div> */}

                </Grid>
                <ModalScannerQr type={2} userEmail={infoUser?.user_email || null} />
            </Grid >

            <ModalJab openModal={openModalJag} setOpenModal={setOpenModalJag} infoModal={infoModalJag} loader={loader} tx_id={txId} nft={nft} infoUser={infoUser} />
            <ModalDark openModal={openModalDark} setOpenModal={setOpenModalDark} infoModal={infoModal} loader={loader} tx_id={txId} nft={nft} infoUser={infoUser} />
            <ModalJaime openModal={openModalJaime} setOpenModal={setOpenModalJaime} infoModal={infoModalJaime} loader={loader} tx_id={txId} nft={nft} infoUser={infoUser} />
            <Loading status={openLoader} />

        </div>
    );
}

export default InfoUser;