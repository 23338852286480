import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import { TextField, Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from '../loading/loading';
import LinkedIn from '../../images/linkedin.png';
import '../../css/dashboard.css';

import actionLinkedIn from "../../actions/linkedInAction";

export default function ModalDark( { openModal, setOpenModal, infoModal, loader, tx_id, nft, infoUser } ) {
    const [ code, setCode ] = React.useState('');
    const [ show, setShow ] = React.useState( false );
    const [ textToPost, setTextToPost ] = React.useState( '' );
    const [ startLloading, setStartLoading ] = React.useState( false );

    React.useEffect( () => setTextToPost( nft?.nft_metadata?.description ), [ nft ] );

    const nftName= infoModal.nft_name;
    
    const handleClose = () => {
        setOpenModal( false );
    };

    // const handleShareClick = (e) => {
    //     e.preventDefault();
    //     const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(infoModal?.nft_metadata?.image)}`;
    //     const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    //     if (isMobile) {
    //         window.location.href = linkedInUrl;
    //     } else {
    //         const newWindow = window.open(linkedInUrl, '_blank', 'noopener,noreferrer');

    //         // Delay the check for the new window
    //         setTimeout(() => {
    //             if (newWindow && newWindow.closed) {
    //                 alert('Unable to open the link. Please allow pop-ups for this site.');
    //             }
    //         }, 5000); // Delay of 100 milliseconds
    //     }
    // };

    const showInput = () => {
        
        if ( !show ) { 
            setShow(true);
        } else {
            setShow( false );
            setTextToPost('');
        };
    };
 
    const handleSubmit = (e) => {
        setTextToPost( e.target.value );
    };
 
    const publicar = () => {
        setShow( false );
        setTextToPost('');
        onClickLoginLinkedIn( textToPost ); 
    };

    
    const onClickLoginLinkedIn = async ( textToPost ) => {

        setStartLoading( true );

        const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

        let baseURL= '';
        if ( baseUrl === 'http://localhost:3000' ) {
            baseURL= baseUrl;
        } else {
            baseURL= process.env.REACT_APP_FRONTEND_URL
        };
        
        console.log( "Base URL_8: ", baseURL );

        const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ixvk4k5ixwpb&redirect_uri=${ baseURL }&state=987654321&scope=openid%20profile%20email%20w_member_social`;
        const newWindow = window.open( linkedinAuthUrl, '_blank' );
        
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent );

        // if (isMobile) {
        //     window.location.href = linkedinAuthUrl;
        // } else {
        //     const newWindow = window.open( linkedinAuthUrl, '_blank', 'width=600,height=600' );
        // };

        const closePopup = () => {
            if ( newWindow && !newWindow.closed ) {
                newWindow.close();
                clearInterval(checkPopup);
            };
        };

        // Verifica el cambio de URL en la ventana emergente para detectar cuando se complete la autorización
        const checkPopup = setInterval( () => {
            try {
                if ( newWindow.location.href.includes( `${baseUrl}/?code=` ) ) {
                    const redirectedUrl = newWindow.location.href;
                    const urlParams = new URLSearchParams( redirectedUrl.split( '?' )[ 1 ] );
                    const code= urlParams.get( 'code' );
                    setCode( code );

                    const data = { baseUrl: baseUrl, nftName: nftName, authCode: code, user: infoUser, textToPost: textToPost };

                    if ( !isMobile ) { 
                        closePopup();
                    };

                    actionLinkedIn.postLinkedIn( data ).then( ( response ) => {
                        setStartLoading( false );
                        window.open( `https://www.linkedin.com/feed/update/${ response.postNFT.id }`, '_blank' );
                    } );  

                };
                
            } catch (error) {
                console.error(error);
            };
        }, 500);

    };


    return (
        <React.Fragment>
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='modalDark'
                maxWidth='md'
            >
                <DialogTitle className='modalDark-box ' id="alert-dialog-title">
                    {/* Modal title here */}
                </DialogTitle>
                {
                    loader
                        ? <CircularProgress color="inherit" />
                        : <DialogContent className='modalDark-box'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={infoModal?.nft_metadata?.image} alt="" className='imgModalJag' />

                                {/* <p
                                    style={{ color: 'white', marginTop: 30 }}
                                    className='linkBlockchain'
                                >
                                    {nftId}
                                </p> */}

                                <Tooltip title= "Compartir tu nft en Linkedin" onClick={ () => onClickLoginLinkedIn() }>
                                {/* <Tooltip title= "Compartir tu nft en Linkedin" onClick={ showInput }> */}
                                    <img src= { LinkedIn } alt="" style={ { width: 50, marginTop: 10 } } />
                                </Tooltip>

                                {/* <Button onClick={ showInput } variant= "contained" > Abrir </Button><br></br> */}
 
                                {
                                    show ?
                                        <>
                                            <p className='textModalJab'> 
                                            Comparte tu BitPin con tus conexiones en LinkedIn como certificado de asistencia al evento.
                                            </p>
                                            <p className='textModalJab'> Texto para la publicación: </p><br></br>
                                            <TextField multiline= { true } rows= { 4 } value= { textToPost } onChange= { handleSubmit } InputProps={{ style: { color: 'white' } }} fullWidth={true}/>
                                            {/* <TextField multiline= { true } rows= { 4 } value= { textToPost } onChange= { handleSubmit } InputProps={{ style: { color: '#2AD2C9' } }} fullWidth={true}/> */}                                            
                                            <Button onClick= { publicar } variant= "contained" > Publicar en LinkedIn </Button><br></br>
                                        </>
                                        : ''
                                }


                                <p className='titleModalJag'>{infoModal.nft_name}</p>
                                <p className='textModalJab'>
                                    {infoModal?.nft_metadata?.description}
                                </p>

                                <a
                                    href={ infoModal?.nft_metadata?.external_url }
                                    target="_blank"
                                    style={{ color: '#2AD2C9', marginTop: 30 }}
                                    className='linkExternalURL'
                                >
                                    { infoModal?.nft_metadata?.external_url }
                                </a>

                                <a
                                    href={`https://gnosisscan.io/tx/${tx_id}`}
                                    target="_blank"
                                    style={{ color: 'white', marginTop: 30 }}
                                    className='linkBlockchain'
                                >
                                    {tx_id}
                                </a>

                            </div>

                        </DialogContent>
                }
                <DialogActions className='modalDark-box '>
                    {/* Dialog actions here */}
                </DialogActions>
                <Loading status={startLloading} />
            </Dialog>
        </React.Fragment>
    );
}
