import * as React from 'react';
import { LoginContextProvider } from '../contexts/loginContext';

import FormLogin from '../components/login/formLogin';
import '../css/login.css';

function Login() {
    return (
        <LoginContextProvider>
            <div className='login'>
                <FormLogin />
            </div>
        </LoginContextProvider>
    );
}

export default Login;