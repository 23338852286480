import React, { useEffect, useState, useContext } from 'react';
import { LoginContext } from '../contexts/loginContext';
import { useNavigate, useLocation } from 'react-router-dom';

import InfoUser from '../components/dashboard/infoUser';
import AlertInfo from '../components/alert/alert';

import '../css/dashboard.css';


function Dashboard() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { openAlert, setOpenAlert, typeAlert, setTypeAlert, messageAlert, setMessageAlert } = useContext(LoginContext);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (state === null || state === undefined) {
            navigate('/login');
        }

        if (state.status === 200) {
            setUser(state.userInformation);
            setOpenAlert(true);
            setTypeAlert('success');
            setMessageAlert(state.message);
        }

        if (state.status === 404) {
            setUser(state.userInformation);
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageAlert(state.message);
        }

        if (state.status === 500) {
            setUser(state.userInformation);
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageAlert(state.message);
        }

    }, [state, navigate]);

    if (state === null || state === undefined) {
        return null;
    }

    return (
        <>
            <div className='dashboard'>
                {user ? <InfoUser infoUser={user} /> : ''}
            </div>
            <AlertInfo openAlert={openAlert} setOpenAlert={setOpenAlert} typeAlert={typeAlert} messageError={messageAlert} />
        </>
    );
}

export default Dashboard;
