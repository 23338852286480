import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LoginContextProvider } from '../contexts/loginContext';

import FormRequestEmail from '../components/dataRequestNft/formRequestEmail';
import '../css/dataRequestNft.css';

function DataRequestNft() {
    const { id } = useParams();
    return (
        <LoginContextProvider>
            <div className='dataRequestNft'>
                <FormRequestEmail idNft={id} />
            </div>
        </LoginContextProvider>
    );
}

export default DataRequestNft;