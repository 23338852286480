import React, { createContext, useState } from 'react';

import actionUser from '../actions/user';
import actionNft from '../actions/nft';

const LoginContext = createContext();

const LoginContextProvider = ({ children }) => {
    // cons
    const [account, setAccount] = useState(null);
    const [userInfo, setUserInfo] = useState('');
    const [openLoader, setOpenLoader] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState('');
    const [messageAlert, setMessageAlert] = useState('');

    const validateLogin = async (info) => {
        try {
            console.log( "User Info in LoginContext: ", info );
            const response = await actionUser.getUser(info);
            console.log( "GetUser from loginCopntex: ", response );
            return response;
        } catch (error) {
            throw error
        }
    };

    const validateMint = async (info) => {
        try {
            const response = await actionUser.getUserMint(info);
            return response;
        } catch (error) {
            throw error
        }
    };

    const requestInfoNft = async (idNft) => {
        try {
            const response = await actionNft.getNft(idNft);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const contextValues = {
        validateLogin,
        validateMint,
        requestInfoNft,
        account, setAccount,
        userInfo, setUserInfo,
        openLoader, setOpenLoader, 
        openAlert, setOpenAlert,
        typeAlert, setTypeAlert,
        messageAlert, setMessageAlert
    }

    return (
        <LoginContext.Provider value={contextValues}>
            {children}
        </LoginContext.Provider>
    )
};

export { LoginContext, LoginContextProvider }