import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/loginContext';
import { Grid } from '@mui/material';

import AlertInfo from '../alert/alert';
import Loading from '../loading/loading';

function FormRequestEmail({ idNft }) {
    const { validateMint } = React.useContext(LoginContext);
    const [email, setEmail] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [typeAlert, setTypeAlert] = React.useState('');
    const [messageError, setMessageError] = React.useState('');
    const [openLoader, setOpenLoader] = React.useState(false);

    const navigate = useNavigate();

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (email === '') {
            console.error('Email no ingresado');
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageError("Error: Por favor, ingrese su correo electrónico.");
        } else if (!emailRegex.test(email.trim())) {
            console.error('Email no válido');
            setOpenAlert(true);
            setTypeAlert('error');
            setMessageError("Error: Por favor, ingrese un correo electrónico válido.");
        } else {
            setOpenLoader(true);
            const cleanedEmail = email.toLowerCase().trim();
            const info = `${cleanedEmail}/${idNft}`
            const request = await validateMint(info);
            console.log(request)
            if (request) {
                setOpenLoader(false);
                navigate(`/dashboard`, { state: request });
            }
        }
    };

    return (
        <>
            <Grid constainer spacing={0} style={{ height: '100%' }}>
                <Grid item xs={12} className='rowDataRequestInfo'>
                    <div className='boxDataRequestEmail'>
                        <p style={{ margin: 0, fontFamily: 'Monospace', fontWeight: 700, fontSize: 35, color: 'rgba(251, 129, 190, 1)' }}>Bienvenido a GreenList</p>

                        <p style={{ fontFamily: 'Nunito', fontSize: 25, maxWidth: 500 }}> Para continuar y reclamar tu BitPin Digital, por favor ingresa tu <span style={{ color: 'rgba(69, 211, 161, 1)' }}> correo electrónico</span>  a continuación:</p>

                        <form action="" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                            <input
                                className='inputRequestEmail'
                                type="text"
                                value={email}
                                placeholder='Email'
                                onChange={handleEmail}
                            />
                            <button className='buttonRequestEmail' type='submit'>Reclamar</button>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <AlertInfo
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                typeAlert={typeAlert}
                messageError={messageError}
            />
            <Loading status={openLoader} />
        </>
    );
}

export default FormRequestEmail;