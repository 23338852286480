import * as React from 'react';
import Grid from '@mui/material/Grid';

import imgNumberOne from '../../images/imgNumberOne.svg';
import imgNumberTwo from '../../images/imgNumberTwo.svg';
import imgNumberThree from '../../images/imgNumberThree.svg';
import arrowOne from '../../images/arrowOne.svg';
import arrowTwo from '../../images/arrowTwo.svg';

function Instructions() {
    return (
        <div style={{marginBottom: 150}}>
            <Grid container spacing={0}>
                <Grid item xs={12} textAlign={'start'}>
                    <p className='titleInstructions'>¿Cómo usarlo?</p>
                </Grid>
            </Grid>

            <Grid container spacing={0}>
                <Grid item xs={12} textAlign={'start'} className='rowInstructions'>
                    <div className='containerInstructions'>
                        <img className='imgNumberInstructions' src={imgNumberOne} alt="" />
                        <p className='textInstructions'>Ingresa con tu correo electrónico</p>
                    </div>

                    <div>
                        <img className='imgArrowInstructions' src={arrowOne} alt="" />
                    </div>

                    <div className='containerInstructions'>
                        <img className='imgNumberInstructions' src={imgNumberTwo} alt="" />
                        <p className='textInstructions'>Reclama tu (NFT) BitPin por medio de QR o NFC</p>
                    </div>

                    <div>
                        <img className='imgArrowInstructions' src={arrowTwo} alt="" />
                    </div>

                    <div className='containerInstructions'>
                        <img className='imgNumberInstructions' src={imgNumberThree} alt="" />
                        <p className='textInstructions'>Visualiza tus BitPins (nuevos y antiguos)</p>
                    </div>

                </Grid>
            </Grid>
        </div>
    );
}

export default Instructions;    