import * as React from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';

import '../../css/home.css';
import SvgPentagono from '../../images/Polygon.svg';

function Header() {
    return (
        <div style={{ marginBottom: 90 }}>
            <img className='imgPentagono' src={SvgPentagono} alt="" />

            <Grid container spacing={0} className='containerTextHeader'>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                    <div style={{ width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                        <p className='titleTextHeader' >GreenList</p>
                        <div className='boxTextHeader'>
                            <p className='textHeader'>
                                GreenList, impulsada por XM, conecta comunidades con tecnología innovadora para acciones ambientales y sociales significativas. Destacamos con proyectos como Conexión Jaguar, promoviendo la biodiversidad y sostenibilidad. Únete a nosotros en la construcción de un futuro más verde y responsable.
                            </p>
                        </div>
                        <Link to={'/login'}>
                            <button className='button'>Ingresar</button>
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Header;