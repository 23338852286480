import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

import LinkedIn from '../../images/linkedin.png';

import '../../css/dashboard.css';

export default function ModalJaime({ openModal, setOpenModal, infoModal, loader, tx_id, nftId }) {
    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='modalDark'
                maxWidth='md'
            >
                <DialogTitle className='modalDark-box ' id="alert-dialog-title">

                </DialogTitle>
                {
                    loader
                        ? <CircularProgress color="inherit" />
                        : <DialogContent className='modalDark-box'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={infoModal?.nft_metadata?.image} alt="" className='imgModalJag' />

                                <p
                                    style={{ color: 'white', marginTop: 30 }}
                                    className='linkBlockchain'
                                >
                                    {nftId}
                                </p>

                                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(infoModal?.nft_metadata?.image)}`} target="_blank">
                                    <Tooltip title="Compartir tu nft en Linkedin">
                                        <img src={LinkedIn} alt="" style={{ width: 50, marginTop: 10 }} />
                                    </Tooltip>

                                </a>

                                <p className='titleModalJag'>{infoModal.nft_name}</p>
                                <p className='textModalJab' style={{ textAlign: 'justify', fontSize: 25 }}>
                                    Este BitPin es una prueba que te encontraste a Jaime Zapata, Gerente de Estrategia, Innovación y Asuntos Corporativos.
                                </p>

                                <p className='textModalJab' style={{ textAlign: 'center', fontSize: 25 }}>
                                    jazapata@xm.com.co
                                </p>
                                <p className='textModalJab' style={{ textAlign: 'center', fontSize: 25, marginTop: 10 }}>
                                    +57 3116351699
                                </p>
                                <a href='https://www.linkedin.com/in/jaime-alejandro-zapata-uribe-434b4819/' target="_blank" className='textModalJab' style={{ textAlign: 'center', fontSize: 25, marginTop: 10 }}>
                                    https://www.linkedin.com/in/jaime-alejandro-zapata-uribe-434b4819/
                                </a>
                                <a href='https://x.com/jazu4?s=11' target="_blank" className='textModalJab' style={{ textAlign: 'center', fontSize: 25, marginTop: 10 }}>
                                    https://x.com/jazu4?s=11
                                </a>
                                <p className='textModalJab' style={{ textAlign: 'center', fontSize: 25, marginTop: 10 }}>
                                    Medellín, Colombia
                                </p>

                                <a
                                    href={`https://gnosisscan.io/tx/${tx_id}`}
                                    target="_blank"
                                    style={{ color: 'white', marginTop: 30 }}
                                    className='linkBlockchain'
                                >
                                    {tx_id}
                                </a>

                            </div>

                        </DialogContent>
                }
                <DialogActions className='modalDark-box '>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}