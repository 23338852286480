import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/loginContext';
import jsQR from 'jsqr';

import { ButtonStyled, Input } from './styles';

const ModalSearchImage = ({ userEmail, text = '' }) => {
  const { validateMint, setOpenLoader, setOpenAlert, setTypeAlert, setMessageAlert } = React.useContext(LoginContext);
  const [qrResult, setQRResult] = useState(null);
  const navigate = useNavigate();

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageData = new Image();
        imageData.onload = async () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = imageData.naturalWidth;
          canvas.height = imageData.naturalHeight;
          context.drawImage(imageData, 0, 0);
          const decodedQR = decodeQRFromCanvas(canvas);
          setQRResult(decodedQR);
          await handleQRResult(decodedQR);
        };
        imageData.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
    e.target.value = null;
  };

  const handleQRResult = async (qrResult) => {
    if (qrResult === null) {
      setOpenAlert(true);
      setTypeAlert('error');
      setMessageAlert('Por favor, seleccione una imagen de QR válida');
    }

    if (qrResult !== null) {
      const qrResultArray = qrResult.split('/');
      const lastElement = qrResultArray[qrResultArray.length - 1];
      const info = `${userEmail}/${lastElement}`;

      try {
        setOpenLoader(true);
        const result = await validateMint(info);
        setOpenLoader(false);

        if (result) {
          setQRResult(null);
          navigate(`/dashboard`, { state: result });
        }
      } catch (error) {
        setOpenLoader(false);
      }
    }
  };

  const decodeQRFromCanvas = (canvas) => {
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const qrCode = jsQR(imageData.data, imageData.width, imageData.height);
    return qrCode ? qrCode.data : null;
  };

  return (
    <div>
      <Input id='inputImage' type="file" accept="image/*" onChange={handleImageChange} style={{ background: 'grey' }} />
      <label htmlFor='inputImage'>
        <ButtonStyled component="span">
          {text}
        </ButtonStyled>
      </label>
    </div>
  );
};

export default ModalSearchImage;