import { createBrowserRouter } from "react-router-dom";

import Home from "../pages/home";
import Login from "../pages/Login";
import Dashboard from "../pages/dashboard";
import DataRequestNft from "../pages/dataRequestNft";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
    },
    // {
    //     path: "/dashboard/:email",
    //     element: <Dashboard />,
    // },
    {
        path: "/nft_claim",
        element: <DataRequestNft />,
    },
    {
        path: "/nft_claim/:id",
        element: <DataRequestNft />,
    }

]);

export default router;