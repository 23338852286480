import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import '../../css/dashboard.css';

export default function ModalJag({ openModal, setOpenModal, infoModal, loader, tx_id, nftId }) {

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='modalDark'
                maxWidth='md'
            >
                <DialogTitle className='modalDark-box-jag' id="alert-dialog-title">

                </DialogTitle>
                {
                    loader
                        ? <CircularProgress color="inherit" />
                        : <DialogContent className='modalDark-box-jag'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={infoModal?.nft_metadata?.image} alt="" className='imgModalJag' />
                                <p
                                    style={{ color: 'white', marginTop: 30 }}
                                    className='linkBlockchain'
                                >
                                    {nftId}
                                </p>
                                <p className='titleModalJag'>{infoModal.nft_name}</p>
                                <p className='textModalJab'>
                                    {infoModal?.nft_metadata?.description}
                                </p>

                                <a
                                    href={`https://gnosisscan.io/tx/${tx_id}`}
                                    target="_blank"
                                    style={{ color: 'white', marginTop: 30 }}
                                    className='linkBlockchain'
                                >
                                    {tx_id}
                                </a>
                            </div>

                        </DialogContent>
                }
                <DialogActions className='modalDark-box-jag'>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}