import * as React from 'react';

import Header from '../components/home/header';
import Benefits from '../components/home/benefits';
import Instructions from '../components/home/instructions';
import FooterHome from '../components/home/footerHome';

import '../css/home.css';

function Home() {
    return (
        <div className='home'>
            <Header />
            <Benefits />
            <Instructions />
            <FooterHome />
        </div>
    );
}

export default Home;