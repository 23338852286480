import React from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'

import actionUserEnv from "../../actions/userEnv";


export default function SwitchEnv( { user, setEnv } ) {

  const [ checked, setChecked ] = React.useState( user.env_mode );

  
  React.useEffect( () => {
  }, [ checked, user ] );

  const handleChange = ( event ) => {
    setChecked( event.target.checked );

    if ( event.target.checked ) {
      cambiarEnv( 1 );
    } else {
      cambiarEnv( 0 );
    }
  };

  const cambiarEnv = async ( envMode ) => {

    const data = { envMode: envMode, userEmail: user.user_email };
    const response = await actionUserEnv.postUserEnv( data );

    if ( response.state === 200 ) {
      setEnv( response.userInformation.env_mode )
    } else if ( response.state === 500 ) {
      alert( 'Hubo un error interno. Vuelve a intentarlo') ;
    }
  };


  const label = { inputProps: { 'aria-label': 'Size switch demo' } };


  return (
    // <FormGroup>
    //   <FormControlLabel control= {
    //     <Switch
    //       checked={checked}
    //       onChange={handleChange}
    //       {...label} defaultChecked size="big"
    //     /> 
    //     } label="Label" />
    // </FormGroup>
  
  <FormGroup>
    <Stack direction= "row" spacing={1} alignItems= "center">
      <p style= { { color: 'white'} } > Off </p>
        <FormControlLabel
            value="top"
            control={ <Switch
              checked={checked}
              onChange={handleChange}
              {...label} defaultChecked size= "big"
              /> 
            }
            label="Test"
            labelPlacement="top"  
            style= { { color: 'white', fontSize: '2rem' } }                   
        />
      <p style= { { color: 'white'} } > On </p>
    </Stack>
  </FormGroup>

  );
}
