import * as React from 'react';
import Grid from '@mui/material/Grid';

import imgFooterHomeOne from '../../images/imgFooterHomeOne.svg';
import imgFooterHomeTwo from '../../images/imgFooterHomeTwo.svg';

import '../../css/home.css';

function FooterHome() {
    return (
        <>
            <Grid
                container
                spacing={0}
                direction={{ xs: 'column-reverse', md: 'row' }}
                justifyContent="space-between"
            >
                <Grid item xs={12} md={6} className='containerFooterHome'>
                    <img className='imgOneFooterHomeOne' src={imgFooterHomeOne} alt="" />
                    <div className='containerTextFooterHome'>
                        <div className='boxTextFooterHome'>
                            <p className='titleFooterhome'>GreenList</p>
                            <p className='textFooterhome'>Una plataforma de XM S.A. E.S.P.</p>
                            <p className='textFooterhomeTwo'>WWW.XM.COM.CO</p>
                            <p className='textFooterhome'>Medellín - Colombia</p>
                            <p className='textFooterhomeThree' >© 2023. Todos los derechos</p>
                            <p className='textFooterhomeThree' style={{ marginTop: 0 }}>reservados para XM S.A. E.S.P.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className='containerFooterHomeTwo'>
                    <img className='imgOneFooterHomeTwo' src={imgFooterHomeTwo} alt="" />
                </Grid>
            </Grid>
        </>
    );
}

export default FooterHome;