import * as React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

// https://mui.com/material-ui/react-tooltip/


const longText = `
El Modo Test te permite crear NFTs de prueba llamados TestPins, y te ayuda a acceder a una 
Blockchain alternativa para compartirlos con tus amigos!
`;

export default function PopUpTest() {
  return (
    <div>
      <Tooltip title= { longText } >
        <Button style= { { color: 'white', fontWeight: 'bold', fontSize: '20px' } } sx= { { m: 1 } } > Test Mode! </Button>
      </Tooltip>
    </div>
  );
}