import { apiLinkedIn } from "./../apis/apiLinkedIn";

const actionLinkedIn = {
  postLinkedIn: ( data ) => apiLinkedIn.post( data ),
  // getLinkedIn: (data) => apiLinkedIn.get(data).then((response) => response),
  // getLinkedIn: (data) => apiLinkedIn.getMint(data).then((response) => response),
  // addLinkedIn: (data) => apiLinkedIn.add(data).then((response) => response),
  // updateLinkedIn: (data) => apiLinkedIn.update(data).then((response) => response),
  // deleteULinkedIn: (data) => apiLinkedIn.delete(data).then((response) => response),
}

export default actionLinkedIn;